import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Row, Col, Button } from 'react-bootstrap'
import posed from 'react-pose'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleDown, faArrowCircleUp } from '@fortawesome/free-solid-svg-icons'

const Partnership = ({location}) => {

  const [ openBugyo, setOpenBugyo ] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      bugyo: file(relativePath: { eq: "bugyoLogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 80) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      bugyo11: file(relativePath: { eq: "bugyo11_logo_C_sub_2.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 80) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      bugyoCloud: file(relativePath: { eq: "bugyo_cloud_A_2.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 80) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      bugyoCloudEdge: file(relativePath: { eq: "bugyo_cloud_Edge_logo_A_2.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 80) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      akikura: file(relativePath: { eq: "akikura.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 80) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      akikura11: file(relativePath: { eq: "akikura11.png" }) {
        childImageSharp {
          fluid(maxWidth: 350, quality: 80) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      ecochange: file(relativePath: { eq: "ecochange_logo_notext.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 80) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      oken: file(relativePath: { eq: "oken.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 80) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      pca: file(relativePath: { eq: "pca.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 80) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      rakurakuseisan: file(relativePath: { eq: "sub_product_RakurakuSeisan.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      gdata: file(relativePath: { eq: "sub_product_gdata_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
    }
  `)

  const props = {
    open: { 
      display: "block",
      opacity: 1,
      height: '100%',
      transition: {
        ease: 'easeOut',
        duration: 300
      }
    },
    closed: { 
      display: "none",
      opacity: 0,
      height: '0',
    }
  }

  const Br = () => <br className="d-lg-block d-none" />
  const Hr = () => <hr className="dots-hr my-5" />
  const Box = posed.div(props)

  const Bugyo = () => (
    <>
      <div className="mt-5">
        <Img fluid={data.bugyo11.childImageSharp.fluid} alt="奉⾏i11"/>
        <p className="mt-3"><strong>累積56万社の導⼊実績！　⾃社のパソコン、サーバーにインストールするパッケージ製品</strong></p>
        <p>
          □　財務会計 ︓ 勘定奉⾏i11<br/>
          □　⼈事労務 ︓ 給与奉⾏i11<br/>
          □　販売仕⼊ ︓ 商蔵奉⾏i11
        </p>
        <p className="text-muted">※弊社でインストール代⾏、クラウドへの移⾏も対応可能です。</p>
      </div>

      <div className="mt-5">
        <Img fluid={data.bugyoCloud.childImageSharp.fluid} alt="奉⾏クラウド" />
        <p className="mt-3"><strong>奉⾏シリーズのSaaS版製品　クラウド環境はＯＢＣが提供</strong></p>
        <p>
          □　財務会計 ︓ 勘定奉⾏クラウド<br/>
          □　⼈事労務 ︓ 給与奉⾏クラウド<br/>
          □　販売仕⼊ ︓ 商蔵奉⾏クラウド
        </p>
      </div>

      <div className="mt-5">
        <Img fluid={data.bugyoCloudEdge.childImageSharp.fluid} alt="奉行クラウドEdge" />
        <p className="mt-3"><strong>業務を効率化するサービス</strong></p>
        <p>
          □　奉行 勤怠管理クラウド<br/>
          □　奉行 給与明細電子化クラウド<br/>
          □　奉行 年末調整申告書クラウド
        </p>
      </div>

      <h3 className="text-center my-5">商蔵奉⾏EDI連携</h3>
      <Row className="mb-5">
        <Col xl={6} className="mb-5 mb-xl-0">
          <div className="shadow-box h-100">
            <div className="h-75">
              <Img fluid={data.akikura.childImageSharp.fluid} alt="商蔵奉⾏クラウド" className="ml-auto mr-auto"/>
              <h3 className="my-5 text-center">API連携</h3>
              <p>
                <strong>「商蔵奉⾏クラウド」と弊社EDI製品の「EcoChange」がAPIによる⾃動連携を開始。</strong><br/>
                API連携することで従来の電話、ＦＡＸ、メール等の⼈⼿を介した受発注作業がなくなります。
              </p>
            </div>
            <div className="text-right mt-3 mb-5">
              <Button 
                variant="info"
                href="https://www.obc.co.jp/bugyo-cloud/apiservice/ecochange" 
                target="_blank" rel="noopener noreferrer"
              >
                詳しくはこちら
              </Button>
            </div>
          </div>
        </Col>

        <Col xl={6}>
          <div className="shadow-box h-100">
            <div className="h-75">
              <Img fluid={data.akikura11.childImageSharp.fluid} alt="商蔵奉⾏クラウド" className="ml-auto mr-auto"/>
              <h3 className="my-5 text-center">CSV連携</h3>
              <p>「商蔵奉行」と弊社EDI製品の「EcoChange」をCSVで連携させ、受発注を行うことが可能です。</p>
            </div>
            <div className="text-right mt-3 mb-5">
              <Button 
                variant="info"
                href="https://www.obc.co.jp/bugyo-cloud/apiservice/ecochange" 
                target="_blank" rel="noopener noreferrer"
              >
                詳しくはこちら
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      

    </>
  )

  const BugyoWrapper = () => (
    <>
      <Row className="bugyo-slidedown align-items-center">
        <Col lg={2} xs={12} className="pr-0">
          <Img fluid={data.bugyo.childImageSharp.fluid} alt="奉⾏製品"/>
        </Col>
        <Col lg className="d-none d-lg-block"><h3 className="m-0">奉⾏製品</h3></Col>
        <Col lg={12}>
          <p>
            累計63万社の導⼊実績を誇る、会計・販売管理など基幹業務パッケージソフトシリーズです。<Br/>
            豊富な商品ラインナップからお客様の業態、業界、課題に合わせて最適なソリューションをご提供致します。<Br/>
            また、当社EcoChangeと商蔵奉⾏を連携させることで受発注業務の効率化を図り、お客様の⼿間を省きます。
          </p>
        </Col>
      </Row>
      <Box pose={openBugyo ? 'open' : 'closed'}>
        <Bugyo />
      </Box>
      <div className="bugyo-slidedown text-right my-3" >
        <Button
        variant="link"
          onClick={()=>setOpenBugyo(!openBugyo)} 
          style={{ cursor: 'pointer' }}
        >
          { openBugyo ? '詳細を閉じる' : '詳細を開く' }
          <FontAwesomeIcon
          className="ml-2"
          size="lg"
          icon={ openBugyo ? faArrowCircleUp : faArrowCircleDown }  
        />
        </Button>
      </div>
      <Hr />
    </>
  )



  return(
    <Layout location={location} title="パートナーシップ" >
      <SEO title="パートナーシップ" description="弊社のパートナーシップ製品をご紹介します。"/>

      <div className="page-head">
				<Container expand="lg">
					<h2>パートナーシップ</h2>
					<p>弊社のパートナーシップ製品をご紹介します。</p>
				</Container>
				<div className="bottom-border" />
			</div>

      <Container expand="lg">
        <BugyoWrapper />

        <Row>
          <Col lg={2} className="pr-0">
            <Img fluid={data.oken.childImageSharp.fluid} className="d-inline-bock" alt="大臣エンタープライズ　販売管理"/>
          </Col>
          <Col lg={10}>
            <h3 className="mt-5 mt-lg-0">大臣エンタープライズ　販売管理</h3>
            <p>
              充実した標準機能をベースに、大臣エンタープライズ公認パートナーとの強力な開発支援と豊富なオプション群で、<Br />
              ユーザー様の販売管理業務に合わせたシステムをご提案します。
            </p>
            <div className="text-right">
              <Button 
                variant="info"
                href="https://www.ohken.co.jp/enterprise/hb.html" 
                target="_blank" rel="noopener noreferrer"
              >
                詳しくはこちら
              </Button>
            </div>
          </Col>
        </Row>
        <Hr />

        <Row>
          <Col lg={2} className="pr-0">
            <Img fluid={data.pca.childImageSharp.fluid} className="d-inline-bock" alt="商魂DX・商管DX　販売管理・仕入・在庫管理"/>
          </Col>
          <Col lg={10}>
            <h3 className="mt-5 mt-lg-0">商魂DX・商管DX　販売管理・仕入・在庫管理</h3>
            <p>
            販売・仕入・在庫の垣根無くリアルタイムに把握。多彩な商品単価設定機能、複雑な取引条件も確実入力。<Br />
            販売業務のフローに対応したPCA商魂DXと仕入在庫管理業務のフローに対応したPCA商管DXでデータを共通管理し、販売促進をサポートします。
            </p>
            <div className="text-right">
              <Button 
                variant="info"
                href="https://pca.jp/area_product/prokon_prokan.html" 
                target="_blank" rel="noopener noreferrer"
              >
                詳しくはこちら
              </Button>
            </div>
          </Col>
        </Row>
        <Hr />

        <Row className="align-items-center">
          <Col lg={2} xs={12} className="pr-0">
            <Img fluid={data.rakurakuseisan.childImageSharp.fluid} alt="楽楽精算"/>
          </Col>
          <Col lg={10} className="d-none d-lg-block"><h3 className="m-0">楽楽精算</h3></Col>
          <Col>
            <p>
              国内導⼊社数No1︕のクラウド型経費精算システムです。PCやスマホでどこでも申請・承認可能。ペーパーレス化が図れます。<Br/>
              申請内容の⾃動チェック、仕訳の⾃動⼊⼒、会計ソフト連携など、会社の規模や業種を問わず経理業務を効率化を実現します。
            </p>
            <div className="text-right">
              <Button 
                variant="info"
                href="https://www.rakurakuseisan.jp/feature/index.php" 
                target="_blank" rel="noopener noreferrer"
              >
                詳しくはこちら
              </Button>
            </div>
          </Col>
        </Row>
        <Hr />
      </Container>
    </Layout>
  )
}

export default Partnership